import { Box, Typography } from '@mui/material';
import { styled, SxProps } from '@mui/material/styles';
import Iconify from '#/components/shared/ui/Iconify';

interface InformativeCardProps {
  cardTitle: string;
  onServiceSelect?: VoidFunction;
  inverse?: boolean;
  selected?: boolean;
  sx?: SxProps;
}

export default function InformativeCard({
  cardTitle,
  onServiceSelect,
  inverse = false,
  selected = false,
  sx,
}: InformativeCardProps) {
  const handleServiceSelect = () => {
    onServiceSelect?.();
  };

  return (
    <StyledInfoCard inverse={inverse} onClick={handleServiceSelect} sx={sx}>
      <Typography
        sx={{
          color: inverse ? 'white' : 'black',
        }}
      >
        {cardTitle}
      </Typography>
      {selected && !inverse && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 2,
            left: 10,
          }}
        >
          <Iconify
            icon="mdi:checkbox-marked-circle"
            sx={{
              color: 'green',
            }}
            width={24}
            height={24}
          />
        </Box>
      )}
      {!inverse && !selected && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 2,
            right: 10,
          }}
        >
          <Iconify
            icon="el:play-alt"
            sx={{
              color: inverse ? 'white' : 'black',
            }}
            width={24}
            height={24}
          />
        </Box>
      )}
    </StyledInfoCard>
  );
}

const StyledInfoCard = styled(Box)<{ inverse?: boolean; selected?: boolean }>`
  ${({ theme, inverse }) => ({
    width: '100%',
    backgroundColor: inverse
      ? theme.palette.primary.main
      : theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 1,
    padding: theme.spacing(2),
    position: 'relative',
    height: 100,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: inverse
        ? theme.palette.primary.dark
        : theme.palette.action.hover,
    },
  })}
`;
